import { combineReducers } from '@reduxjs/toolkit'
import { crud } from "./crud";
import { auth, authPreloadedState } from './auth'
import { user, userPreloadedState } from './user'
import { widgets, widgetsPreloadedState } from './widgets'
import { banners, bannersPreloadedState } from './banners'
import { stores, storesPreloadedState } from './stores'
import { addresses, addressesPreloadedState } from './addresses'
import { orders, ordersPreloadedState } from './orders'
import { paypal, paypalPreloadedState } from './paypal'
import { staticPages, staticPagesPreloadedState } from './static-pages'
import { contentTitles, contentTitlesPreloadedState } from './content-titles'

const defaultCRUD = {
    list: {
        items: [],
    },
    all: [],
    fetch: {
        data: {},
    },
    save: {},
    delete: {},
};

export const root = combineReducers({
    user: user,
    widgets: widgets,
    banners: banners,
    products: crud("products"),
    auth: auth,
    carts: crud("carts"),
    cartItems: crud("cart-items"),
    cartShippingFixedOptions: crud("cart-shipping-fixed-options"),
    cartShippingSupplementOptions: crud("cart-shipping-supplement-options"),
    stores: stores,
    wishlists: crud("wishlists"),
    wishListItems: crud("wishlist-items"),
    comparisonLists: crud("comparison-lists"),
    comparisonListItems: crud("comparison-list-items"),
    addresses: addresses,
    customers: crud("customers"),
    customerAddresses: crud("customer-addresses"),
    orders: crud("orders", orders),
    orderItems: crud("order-items"),
    paypal: paypal,
    categories: crud("categories"),
    discounts: crud("discounts"),
    shippingSupplements: crud("shipping-supplements"),
    shippingSupplementsFixed: crud("shipping-supplements-fixed"),
    contentBlocks: crud("content-blocks"),
    staticPages: staticPages,
    contentTitles: contentTitles,
    collections: crud("collections"),
})

export const preloadedState = {
    widgets: Object.assign({}, widgetsPreloadedState),
    banners: Object.assign({}, bannersPreloadedState),
    stores: Object.assign({}, storesPreloadedState),
    products: Object.assign({}, defaultCRUD),
    user: Object.assign({}, userPreloadedState),
    auth: Object.assign({}, authPreloadedState),
    wishlists: Object.assign({}, defaultCRUD),
    wishListItems: Object.assign({}, defaultCRUD),
    comparisonLists: Object.assign({}, defaultCRUD),
    comparisonListItems: Object.assign({}, defaultCRUD),
    addresses: Object.assign({}, addressesPreloadedState),
    customers: Object.assign({}, defaultCRUD),
    customerAddresses: Object.assign({}, defaultCRUD),
    orders: Object.assign({}, defaultCRUD, ordersPreloadedState),
    orderItems: Object.assign({}, defaultCRUD),
    paypal: Object.assign({}, paypalPreloadedState),
    categories: Object.assign({}, defaultCRUD),
    carts: Object.assign({}, defaultCRUD),
    cartItems: Object.assign({}, defaultCRUD),
    cartShippingFixedOptions: Object.assign({}, defaultCRUD),
    cartShippingSupplementOptions: Object.assign({}, defaultCRUD),
    discounts: Object.assign({}, defaultCRUD),
    shippingSupplements: Object.assign({}, defaultCRUD),
    shippingSupplementsFixed: Object.assign({}, defaultCRUD),
    contentBlocks: Object.assign({}, defaultCRUD),
    staticPages: Object.assign({}, staticPagesPreloadedState),
    contentTitles: Object.assign({}, contentTitlesPreloadedState),
    collections: Object.assign({}, defaultCRUD),
}
