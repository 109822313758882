import { createReducer } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'


export const addresses = combineReducers({
    create: createReducer([], { 'ADDRESS_ADD': (state, action) => action.payload }),
    update: createReducer([], { 'ADDRESS_UPDATE': (state, action) => action.payload }),
    delete: createReducer([], { 'ADDRESS_DELETE': (state, action) => action.payload }),
    changeDefaultShipping: createReducer([], { 'ADDRESS_CHANGE_DEFAULT_SHIPPING': (state, action) => action.payload }),
    changeDefaultBilling: createReducer([], { 'ADDRESS_CHANGE_DEFAULT_BILLING': (state, action) => action.payload }),
})

export const addressesPreloadedState = {
    create: {},
    update: {},
    delete: {},
    changeDefaultShipping: {},
    changeDefaultBilling: {},
}
