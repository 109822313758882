import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
//import { fetchProducts } from "./redux/actions/productActions";
import { root as rootReducer, preloadedState } from "./reducers";
//import products from "./data/products.json";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from 'react-dom/client'

import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(
    rootReducer,
    //load(),
    preloadedState,
    //composeWithDevTools(applyMiddleware(thunk, save()))
    composeWithDevTools(applyMiddleware(thunk))
);

// fetch products from json file
//store.dispatch(fetchProducts(products));

createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <App />
    </Provider>
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
