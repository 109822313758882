import { createReducer } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'

export const orders = combineReducers({
    create: createReducer([], { 'ORDERS_CREATE': (state, action) => action.payload }),
    fastCreate: createReducer([], { 'ORDERS_FAST_CREATE': (state, action) => action.payload }),
    setProcessing: createReducer([], { 'ORDERS_SET_PROCESSING': (state, action) => action.payload }),
    changeBillingAddress: createReducer([], { 'ORDERS_CHANGE_BILLING_ADDRESS': (state, action) => action.payload }),
    changeShippingAddress: createReducer([], { 'ORDERS_CHANGE_SHIPPING_ADDRESS': (state, action) => action.payload }),
})

export const ordersPreloadedState = {
    infoByPayPalId: {},
    changeBillingAddress: {},
    changeShippingAddress: {}
}
