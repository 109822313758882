import { createReducer } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'

export const banners = combineReducers({
    list: createReducer([], { 'BANNERS_LIST': (state, action) => action.payload }),
})

export const bannersPreloadedState = {
    list: [],
}
