import {RequireAuth, AuthProvider} from 'react-auth-kit'
import PropTypes from "prop-types";
import React, { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { HelmetProvider } from 'react-helmet-async';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

const Init = lazy(() => import("./components/Init"));

const Home = lazy(() => import("./pages/home"));
const Catalog = lazy(() => import("./pages/catalog"));
const Product = lazy(() => import("./pages/product"));

//auth pages
const AuthPage = lazy(() => import("./pages/auth"));
const ResetPasswordPage = lazy(() => import("./pages/auth/ResetPassword"));
const SetNewPasswordPage = lazy(() => import("./pages/auth/SetNewPassword"));

// blog pages
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogDetailsStandard = lazy(() => import("./pages/blog/BlogDetailsStandard"));

// other pages
const StaticPage = lazy(() => import("./pages/static-page"));
const Contact = lazy(() => import("./pages/contact-us"));
const MyAccount = lazy(() => import("./pages/my-account"));
const MyOrders = lazy(() => import("./pages/my-orders"));
const Order = lazy(() => import("./pages/order"));

const Cart = lazy(() => import("./pages/cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const Inactive = lazy(() => import("./pages/other/Inactive"));

const PRoute = ({ component: Component, ...rest }) => (
    <RequireAuth loginPath={'/auth'} {...rest}>{Component}</RequireAuth>
)

window.getCookie = function(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
}

const helmetContext = {};

const App = () => {

    return (
        <I18nextProvider i18n={i18n}>
            <AuthProvider
                authType = {'cookie'}
                authName={'_auth_t'}
                cookieDomain={window.location.hostname}
                cookieSecure={window.location.protocol === "https:"}
                refreshToken
            >
                <HelmetProvider context={helmetContext}>
                    <ToastProvider placement="top-right">
                        <BreadcrumbsProvider>
                            <ScrollToTop>
                                <Suspense
                                    fallback={
                                        <div className="Shoplum.me-preloader-wrapper">
                                            <div className="Shoplum.me-preloader">
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    }
                                >
                                    <Init />
                                    <Router>
                                        <Routes>
                                            <Route exact path={"/"} element={<Home />} />

                                            <Route path={"/catalog"} element={<Catalog />} />
                                            <Route path={"/product/:slug"} element={<Product />} />
                                            <Route path={"/cart"} element={<Cart />} />
                                            <Route path={"/wishlist"} element={<Wishlist />} />
                                            <Route path={"/compare"} element={<Compare />} />

                                            <Route path={"/blog"} element={<BlogNoSidebar />} />
                                            <Route path={"/blog/{slug}"} element={<BlogDetailsStandard />} />
                                            <Route path={"/static/:url"} element={<StaticPage />} />
                                            <Route path={"/contact"} element={<Contact />} />

                                            <Route path={"/auth"} element={<AuthPage />} />
                                            <Route path={"/auth/reset-password"} element={<ResetPasswordPage />} />
                                            <Route path={"/auth/set-new-password"} element={<SetNewPasswordPage />} />

                                            <Route path={"/inactive"} element={<Inactive />} />

                                            <Route path={"/checkout/:orderId"} element={
                                                <PRoute component={<Checkout />} />
                                            } />
                                            <Route path={"/order/:orderId"} element={
                                                <PRoute component={<Order />} />
                                            } />
                                            <Route path={"/my-account"} element={
                                                <PRoute component={<MyAccount />} />
                                            } />
                                            <Route path={"/my-orders"} element={
                                                <PRoute component={<MyOrders />} />
                                            } />

                                            <Route exact component={NotFound} />
                                        </Routes>
                                    </Router>
                                </Suspense>
                            </ScrollToTop>
                        </BreadcrumbsProvider>
                    </ToastProvider>
                </HelmetProvider>
            </AuthProvider>
        </I18nextProvider>
    );
};

App.propTypes = {
    dispatch: PropTypes.func
};

export default connect()(App);
