import { createReducer } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'

export const paypal = combineReducers({
    create: createReducer([], { 'PAYPAL_ORDER_CREATE': (state, action) => action.payload }),
})

export const paypalPreloadedState = {
    create: {},

}
