import { createReducer } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'

export const contentTitles = combineReducers({
    title: createReducer([], { 'CONTENT_TITLES_GET_TITLE': (state, action) => action.payload }),
})

export const contentTitlesPreloadedState = {
    title: {},
}
