import { createReducer } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'

export const staticPages = combineReducers({
    page: createReducer([], { 'STATIC_PAGES_GET_PAGE': (state, action) => action.payload }),
})

export const staticPagesPreloadedState = {
    page: {},
}
