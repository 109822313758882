import { createReducer } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'

export const user = combineReducers({
    avatarGet: createReducer([], { 'USER_AVATAR_GET': (state, action) => action.payload }),
    info: createReducer([], { 'USER_INFO': (state, action) => action.payload }),
    update: createReducer([], { 'USER_UPDATE': (state, action) => action.payload }),
    balanceWallet: createReducer([], {'USER_WALLET_BALANCE': (state, action) => action.payload }),
})

export const userPreloadedState = {
    avatarGet: {},
    info: {},
    balanceWallet: {},
    update: {},
}
