import { combineReducers, createReducer } from '@reduxjs/toolkit'

export const crud = (name, additional = {}) => combineReducers({
    list: createReducer([], { [name.toUpperCase() + "_LIST"]: (state, action) => action.payload }),
    all: createReducer([], { [name.toUpperCase() + "_ALL"]: (state, action) => action.payload }),
    fetch: createReducer([], { [name.toUpperCase() + "_FETCH"]: (state, action) => action.payload }),
    save: createReducer([], { [name.toUpperCase() + "_SAVE"]: (state, action) => action.payload }),
    delete: createReducer([], { [name.toUpperCase() + "_DELETE"]: (state, action) => action.payload }),
    ...additional
})
