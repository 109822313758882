import { createReducer } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'

export const auth = combineReducers({
    login: createReducer([], {'LOGIN': (state, action) => action.payload}),
    register: createReducer([], {'REGISTER': (state, action) => action.payload}),
    resetPassword: createReducer([], {'RESET_PASSWORD': (state, action) => action.payload}),
    setNewPassword: createReducer([], {'SET_NEW_PASSWORD': (state, action) => action.payload}),
    user: createReducer([], { 'AUTH_USER': (state, action) => action.payload }),
})

export const authPreloadedState = {
    login: {},
    register: {},
    user: {},
    resetPassword: {},
    setNewPassword: {},
}
