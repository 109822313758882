import { createReducer } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'

export const widgets = combineReducers({
    homepage: createReducer([], { 'WIDGETS_HOMEPAGE': (state, action) => action.payload }),
})

export const widgetsPreloadedState = {
    homepage: {},
}
